<template>
  <div>
    <div v-if="loading" class="text-center my-5">
      <b-spinner
        style="width: 3rem; height: 3rem"
        label="Large Spinner"
      ></b-spinner>
    </div>
    <div v-if="!loading">
      <div class="row">
        <div class="col-12 mx-auto">
          <div class="card card-custom gutter-b example example-compact">
            <div
              class="
                card-header
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <div class="card-title">
                <h3 class="card-label">Daromadlar ruyhati</h3>
              </div>
              <div class="card-button">
                <v-btn to="/incomes/create" color="primary" small> + </v-btn>
              </div>
            </div>
            <div class="card-body">
              <v-data-table
                :headers="headers"
                :items="list"
                :loading="isLoading"
                :items-per-page="10"
                single-select
                @click:row="rowClick"
                item-key="id"
                hide-default-footer
                class="row-pointer"
              >
                <template v-slot:[`item.main_income_info`]="{ item }">
                  <div v-if="item.income_type_description !== null">
                    {{ item.income_type_description.main_income_info.name }}
                  </div>
                </template>
                <template v-slot:[`item.category_info`]="{ item }">
                  <div v-if="item.income_type_description !== null">
                    {{ item.income_type_description.category_info.name }}
                  </div>
                </template>
                <template v-slot:[`item.income_type`]="{ item }">
                  <div v-if="item.income_type_description !== null">
                    {{ item.income_type_description.name }}
                  </div>
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <div style="text-align: right !important">
                    <action :index="item"></action>
                  </div> </template
              ></v-data-table>
            </div>
            <div class="text-left my-5">
              <v-pagination
                v-model="currentPage"
                :total-visible="10"
                @input="getPostData(currentPage)"
                :length="Math.ceil(getCount.count / 10)"
              ></v-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import action from './Action'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
export default {
  components: { action },
  data() {
    return {
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      perPage: 10,
      loading: true,
      currentPage: 1,
      headers: [
        {
          text: '#',
          value: 'index'
        },
        {
          text: 'Summasi',
          value: 'income_amount'
        },
        {
          text: 'Sana',
          value: 'oper_date'
        },
        {
          text: 'Asosiy xarajat',
          value: 'main_income_info'
        },
        {
          text: 'kategoriya',
          value: 'category_info'
        },
        {
          text: 'Sub Kategoriya',
          value: 'income_type'
        },
        {
          text: 'Komment',
          value: 'comment'
        },
        {
          text: '',
          value: 'action',
          sortable: false
        }
      ]
    }
  },
  created() {
    // this.$store.dispatch("kontragentContractAktSverka");
    if (this.$route.query.page !== undefined) {
      this.getPostData(parseInt(this.$route.query.page, 10))
      this.currentPage = parseInt(this.$route.query.page, 10)
    } else {
      this.getPostData(this.currentPage)
    }
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        this.$router.push('/incomes/details/' + item.id + '/')

        row.select(false)
      }
    },
    getPostData(value) {
      if (parseInt(this.$router.currentRoute.query.page, 10) !== value) {
        this.$router.push({
          path: this.$router.currentRoute.path,

          query: { page: value }
        })
      }
      this.$store.dispatch('getIncomeList', value)
    }
  },
  watch: {
    list: function (val) {
      if (val.length !== 0) {
        this.lists = []
        this.lists = [...val]
        this.loading = false
      }
    }
  },
  computed: {
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    list() {
      const data = this.$store.state.requests.incomeList.results

      if (data !== undefined) {
        data.forEach((element, index) => {
          element.index = index + 1
        })
      }

      return data
    },
    getCount() {
      return this.$store.state.requests.incomeList
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Moliya' },
      { title: 'Daromadlar' }
    ])
  }
}
</script>
